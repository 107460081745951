// @mui
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material'
// layouts
import Layout from '../../../layouts'
// components
import Page from '@/components/Page'
// sections
import { LandingHero } from '@/sections/landing'
import pageService, { settingService, Section, GlobalSetting, SeoMetadata } from '@/services/page'
import pathService from '@/services/path'
import type { GetStaticPaths, GetStaticProps } from 'next'
import { APPS } from '@/appConfig'
import type { ParsedUrlQuery } from 'querystring'
import { notEmpty } from '@/utils/common'
import { renderComponent } from '@/services/pageRender'
import useApp from '@/hooks/useApp'
import { productService } from '@/services/product'
import { TagsForAllProductsFragment } from '@/generated/graphql'
interface PathProps extends ParsedUrlQuery {
  appId: string
}

interface IndexProps {
  title?: string
  sections?: Section[]
  globalSetting?: GlobalSetting
  seoMetadataItemsCollection?: SeoMetadata[]
  tags: TagsForAllProductsFragment[] | []
}

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  height: '100%',
}))

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}))

// ----------------------------------------------------------------------

HomePage.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout variant="main">{page}</Layout>
}

// ----------------------------------------------------------------------

export default function HomePage({
  title,
  sections,
  seoMetadataItemsCollection,
  globalSetting,
  tags,
}: IndexProps) {
  const theme = useTheme()
  const { logo, host, title: rootTitle, description: rootDescription, sectionColors } = useApp()
  const [colorFirst, colorSection] = sectionColors ?? []
  const sectionBackgroundColors = [
    colorFirst ?? theme.palette.background.neutral,
    colorSection ?? theme.palette.background.default,
  ]

  const heroSection = sections?.find((section) => section.key === 'SectionsHero')

  const sectionComponents =
    sections
      ?.map((section, index) =>
        renderComponent(section, sectionBackgroundColors, index, globalSetting)
      )
      .filter(notEmpty) ?? []

  return (
    <Page
      title={title ?? ''}
      metadataItems={seoMetadataItemsCollection}
      jsonLd={{
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: title ?? rootTitle ?? '',
        url: `https://${host}`,
        '@id': `https://${host}`,
        description: rootDescription ?? '',
        publisher: {
          '@type': 'Organization',
          name: rootTitle ?? '',
          logo: {
            '@type': 'ImageObject',
            url: logo ?? '',
          },
        },
      }}
    >
      <RootStyle>
        {heroSection?.key === 'SectionsHero' && (
          <LandingHero
            contentfulDataFragment={heroSection}
            contentfulDataForProductSearchTagsFragment={tags}
          />
        )}
        <ContentStyle>{sectionComponents}</ContentStyle>
      </RootStyle>
    </Page>
  )
}

export const getStaticPaths: GetStaticPaths<PathProps> = async () => {
  const { getDefaultPaths } = pathService()
  const paths = APPS.flatMap((app) => getDefaultPaths(app.id))

  return {
    paths,
    fallback: true,
  }
}

export const getStaticProps: GetStaticProps<IndexProps, PathProps> = async ({ params, locale }) => {
  if (!params) throw new Error('No path parameters found')

  const { appId } = params

  const { getPage } = pageService(appId)
  const { getGlobalSetting } = settingService(appId)
  const { getAllProductSearchTags } = productService(appId)
  const [{ title, sections, seoMetadataItemsCollection }, globalSetting, tags] = await Promise.all([
    getPage('/', { locale }),
    getGlobalSetting({ locale }),
    getAllProductSearchTags({ locale }),
  ])

  return {
    props: {
      title: title ?? globalSetting.seoMetadataItemsMap.title,
      sections,
      seoMetadataItemsCollection,
      globalSetting,
      tags: tags ?? [],
    },
    revalidate: 3600,
  }
}
